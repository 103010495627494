import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { navigate } from "gatsby-link";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useSpring, animated, config, useTrail } from "react-spring";
import ButtonOutline from "./ButtonOutline";
import Button from "./Button";

const ButtonSet = styled.div(
  (props) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${props.theme.size.base * 2}px;
  `
);

const SplashStyles = styled.div(
  (props) => `
  width: 100%;
  position: relative;
  overflow: hidden;
  ${
    props.theme.dark
      ? `
      background: ${props.theme.color.tertiary[15]};
      background: linear-gradient(
        -20deg,
        ${props.theme.color.primary[15]},
        ${props.theme.color.secondary[20]}
      );
    `
      : `
    background: ${props.theme.color.secondary[90]};
    background: linear-gradient(
      -20deg,
      ${props.theme.color.primary[85]},
      ${props.theme.color.secondary[90]}
    );
    `
  };
  ${
    props.breakpoints.lg &&
    `
    border-radius:  ${props.theme.size.base * 3}px 0 0 ${
      props.theme.size.base * 3
    }px;
  `
  };
  ${
    props.breakpoints.xl &&
    `
    border-radius:  ${props.theme.size.base * 4}px 0 0 ${
      props.theme.size.base * 4
    }px;
  `
  };
  svg {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .splash-content {
    position: relative;
    padding: ${props.theme.size.base * 13}px ${props.theme.size.base * 4}px ${
    props.theme.size.base * 4
  }px;
  h1 {
    font-size: ${props.theme.size.fontSize[9]};
    line-height: ${props.theme.size.lineHeight[9]};
    color: ${props.theme.color.primary[60]};
    transition: color 450ms ease;
    font-weight: 200;
    margin: 0;
  }
  h2 {
    font-size: ${props.theme.size.fontSize[6]};
    line-height: ${props.theme.size.lineHeight[6]};
    font-weight: 600;
    color: ${props.theme.color.accent[60]};
    transition: color 450ms ease;
    margin: 0;
  }
  p {
    font-size: ${props.theme.size.fontSize[5]};
    line-height: ${props.theme.size.lineHeight[5]};
    font-weight: 300;
  }
  a { 
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
    text-decoration: none;
    &:hover {
      color: ${props.theme.color.primary[70]};
    }
  }
  ${
    props.breakpoints.md &&
    `
    padding: ${props.theme.size.base * 12}px;
    max-width: ${props.theme.size.base * 48}px;
  `
  };
  ${
    props.breakpoints.lg &&
    `
    h1 {
      padding-right: ${props.theme.size.base * 12}px;
      font-size: ${props.theme.size.fontSize[12]};
      line-height: ${props.theme.size.lineHeight[12]};
    }
    padding: ${props.theme.size.base * 12}px;
    max-width: ${props.theme.size.base * 82}px;
  `
  };
  ${
    props.breakpoints.xl &&
    `
    h1 {
      padding-right: ${props.theme.size.base * 12}px;
    }
    padding: ${props.theme.size.base * 12}px;
    max-width: ${props.theme.size.base * 102}px;
  `
  };
  }
`
);

const Splash = (props) => {
  const breakpoints = useBreakpoint();

  const { opacity, numberx, numbery } = useSpring({
    from: {
      opacity: 1,
      numberx: 0,
      numbery: 0,
    },
    config: {
      duration: 4000,
    },
    to: async (next) => {
      while (1) {
        await next({ opacity: 0, numberx: 2400, numbery: 600 });
        await next({ reset: true });
      }
    },
    delay: 1000,
  });
  const { opacity2, numberx2, numbery2 } = useSpring({
    from: {
      opacity2: 1,
      numberx2: 0,
      numbery2: 0,
    },
    config: {
      duration: 4000,
    },
    to: async (next) => {
      while (1) {
        await next({ opacity2: 0, numberx2: 2400, numbery2: 600 });
        await next({ reset: true });
      }
    },
    delay: 1500,
  });
  const { opacity3, numberx3, numbery3 } = useSpring({
    from: {
      opacity3: 1,
      numberx3: 0,
      numbery3: 0,
    },
    config: {
      duration: 4000,
    },
    to: async (next) => {
      while (1) {
        await next({ opacity3: 0, numberx3: 2400, numbery3: 600 });
        await next({ reset: true });
      }
    },
    delay: 2000,
  });

  return (
    <SplashStyles theme={props.theme} breakpoints={breakpoints}>
      <svg
        xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        viewBox={
          breakpoints.lg
            ? "0 200 1920 1280"
            : breakpoints.xl
            ? "150 400 1920 1280"
            : "150 200 2070 1280"
        }
        {...props}
      >
        <defs>
          <linearGradient
            id="kuaa-home_svg__a"
            x1={1501.716}
            y1={465.76}
            x2={1752.103}
            y2={465.76}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={props.theme.color.primary[30]} />
            <stop offset={1} stopColor={props.theme.color.primary[60]} />
          </linearGradient>
          <linearGradient
            id="kuaa-home_svg__d"
            x1={1428.876}
            y1={184.794}
            x2={1518.943}
            y2={184.794}
            xlinkHref="#kuaa-home_svg__a"
          />
          <linearGradient
            id="kuaa-home_svg__e"
            x1={1527.975}
            y1={177.511}
            x2={1568.19}
            y2={177.511}
            xlinkHref="#kuaa-home_svg__a"
          />
          <linearGradient
            id="kuaa-home_svg__f"
            x1={1355.257}
            y1={235.674}
            x2={1477.46}
            y2={235.674}
            xlinkHref="#kuaa-home_svg__a"
          />
          <linearGradient
            id="kuaa-home_svg__g"
            x1={1355.437}
            y1={307.418}
            x2={1459.869}
            y2={307.418}
            xlinkHref="#kuaa-home_svg__a"
          />
          <linearGradient
            id="kuaa-home_svg__h"
            x1={1584.667}
            y1={216.51}
            x2={1640.949}
            y2={216.51}
            xlinkHref="#kuaa-home_svg__a"
          />
          <radialGradient
            id="kuaa-home_svg__b"
            cx={1842.363}
            cy={968.234}
            r={666.833}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={props.theme.color.secondary[15]} />
            <stop offset={1} stopColor={props.theme.color.secondary[10]} />
          </radialGradient>
          <radialGradient
            id="kuaa-home_svg__c"
            cx={1842.363}
            cy={468.234}
            r={666.833}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={props.theme.color.secondary[40]} />
            <stop offset={1} stopColor={props.theme.color.secondary[15]} />
          </radialGradient>
        </defs>
        <path
          d="m1723.965 563.28 25.655-9.337c3.048-1.11 3.191-1.507.969-3.977q-8.234-9.345-16.431-18.586l-67.5-76.4-84.29-95.488q-30.2-34.14-60.374-68.35c-2.541-2.872-5.031-3.865-7.807-2.8-3.131 1.2-4.036 3.77-4.1 6.9-.26 11.141-.571 22.3-.831 33.44q-1.525 63.381-3 126.742-1.293 53.112-2.569 106.276c-.657 26.013-1.282 51.957-1.94 77.971-.118 4.415.108 4.563 4.274 3.047l50.039-18.213c5.131-1.868 6.535-3.7 6.436-9.189-.269-19.865-.487-39.749-.876-59.629-.052-2.512.724-3.543 3.061-4.393q40.441-14.547 80.774-29.4c2.54-.924 3.788-.343 5.363 1.615 12.5 15.53 25.15 31 37.75 46.5 3.026 3.731 5.47 4.28 9.991 2.635 8.447-3.19 16.931-6.278 25.415-9.366Zm-164.614-151c.2-.074.439-.217.642-.291q27.952 34.355 56.186 69.011l-55.273 20.118c-.489-29.792-1.038-59.279-1.555-88.834Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__a)"
        />
        <circle
          cx={1936.902}
          cy={1408.525}
          r={501.328}
          fill="url(#kuaa-home_svg__b)"
        />
        <path
          d="M1859.99,528.332a499.056,499.056,0,0,0-249.585,24.128c-127.534,65.128-189.744,200.642-143.954,348.057,60.169,193.709,284.027,334.6,500,314.7,202.264-18.642,325.877-171.707,291.332-350.177C2200.339,693.146,2051.389,558.443,1859.99,528.332Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__c)"
        />
        <g
          transform="rotate(-20 2685.512 46.957)"
          fill="none"
          stroke={props.theme.color.accent[50]}
          strokeMiterlimit={10}
          strokeWidth={6}
        >
          <animated.ellipse
            cx={1516.5}
            cy={293.5}
            rx={numberx}
            ry={numbery}
            style={{
              opacity: opacity,
            }}
          />
          <animated.ellipse
            cx={1516.5}
            cy={293.5}
            rx={numberx2}
            ry={numbery2}
            style={{
              opacity: opacity2,
            }}
          />
          <animated.ellipse
            cx={1516.5}
            cy={293.5}
            rx={numberx3}
            ry={numbery3}
            style={{
              opacity: opacity3,
            }}
          />
        </g>
        <path
          d="m1452.489 146.921 15.732 24.592c2.389 3.734 4.98 3.873 7.821.388 1.765-2.157 3.427-4.375 5.267-6.514 2.638-3.1 5.852-2.589 7.342 1.143q8.661 22.569 17.219 45.076a7954.98 7954.98 0 0 1 12.542 33.05c1.073 2.78.517 4.419-1.633 5.473-2.1 1.025-3.743.469-5.312-2.185-9-14.972-17.909-30-26.909-44.974-2.456-4.141-4.853-4.276-7.88-.479-2.625 3.22-4.939 6.625-7.7 9.737-1.088 1.21-2.988 2.553-4.319 2.378s-3.007-1.991-3.549-3.441c-5.358-14.093-10.533-28.3-15.8-42.455-5.309-14.317-10.691-28.651-15.879-42.981-.543-1.449-.834-3.51-.194-4.817 1.386-2.748 4.936-2.516 6.771.353 3.309 5.071 6.543 10.126 9.806 15.227Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__d)"
        />
        <path
          d="M1549.487 243.774c2.317 1.213 4.263-.16 4.881-3.507 3.118-15.793 6.31-31.569 9.428-47.362 1.471-7.231 2.943-14.461 4.265-21.725.683-3.646-1.414-5.32-4.87-4.2-2.113.71-4.271 1.449-6.384 2.159-3.969 1.32-5.643.208-5.7-3.992-.135-16.664-.286-33.253-.375-49.946.011-2.19-.417-4.162-2.917-4.36-2.41-.255-3.476 1.389-3.923 3.6-1.163 6.007-2.476 11.981-3.713 17.971-4.055 19.345-8.2 38.747-12.107 58.124-.3 1.344.083 3.541 1.014 4.293s2.991.462 4.437.114a81.189 81.189 0 0 0 7.867-2.85c5.651-2.2 7.585-1.387 7.352 5.372-.515 13.743-.731 27.551-1 41.268-.055 1.318.16 2.657.2 3.723a10.834 10.834 0 0 0 1.545 1.318Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__e)"
        />
        <path
          d="M1357.167 212.905c5.247 4.088 10.555 8.073 15.8 12.161q23.1 17.758 46.225 35.441c3.149 2.414 5.7 1.293 6.093-2.614.4-3.787.712-7.515 1.143-11.256.39-3.2 2.436-4.318 5.333-2.9 3.151 1.515 6.285 3.1 9.42 4.692 10.353 5.25 20.661 10.529 31.015 15.778 2.673 1.37 5.333.311 5.26-2.21a5.793 5.793 0 0 0-2.164-3.685c-10.29-7.858-20.686-15.583-30.992-23.366q-12.6-9.461-25.226-18.968c-2.883-2.2-5.526-1.214-6.057 2.27-.31 2.124-.545 4.264-.826 6.433-.57 4.729-2.176 5.628-6.369 3.689-.743-.359-1.5-.643-2.245-1q-21.495-10.124-43-20.174a11.284 11.284 0 0 0-3.182-.66c-2.884 1.715-2.741 4.447-.228 6.369Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__f)"
        />
        <path
          d="M1456.234 297.139c-10.062-.88-20.064-1.668-30.1-2.5-8.145-.694-16.29-1.388-24.481-2.052-3.579-.278-4.7 1.276-3.31 4.556.717 1.723 1.6 3.405 2.347 5.173.9 2.312.187 3.6-2.267 4.082a25.462 25.462 0 0 1-2.69.309q-18.467 2.283-36.963 4.52c-1.728.207-3.369.551-3.335 2.712 0 2.114 1.631 2.55 3.4 2.7 5.18.473 10.389.991 15.569 1.464 15.03 1.424 30.06 2.848 45.151 4.168 1.153.1 2.931-.335 3.5-1.149.542-.859.169-2.546-.29-3.665-.976-2.327-2.254-4.526-3.367-6.767-1.574-3.164-.811-4.679 2.825-5.015 5.633-.524 11.3-1 16.93-1.525 5.9-.5 11.823-1.081 17.742-1.659 2.525-.267 3.777-2.223 2.411-4.559a16.513 16.513 0 0 0-3.072-.793Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__g)"
        />
        <path
          d="M1635.3 172.451c-3.855 4.006-7.755 8.041-11.61 12.048q-15.144 15.753-30.318 31.46c-2.693 2.813-2.083 4.67 1.709 5.581 2.344.554 4.779 1.05 7.123 1.6 4.136.948 4.562 2.216 2.216 5.771-6.314 9.494-12.643 19.062-18.956 28.556-.942 1.436-1.254 2.855.238 4.083s2.688.591 3.958-.735c7.118-7.634 14.294-15.176 21.47-22.719 5.554-5.864 11.184-11.711 16.709-17.621 2.3-2.431 1.7-4.361-1.505-5.262-1.627-.436-3.344-.813-4.97-1.249-2.971-.731-3.663-2.213-2.052-4.912 2.354-3.945 4.812-7.828 7.195-11.727 4.564-7.412 9.1-14.87 13.738-22.265.9-1.408 1.088-2.814-.3-3.785-.785-.525-2.033-.369-2.676-.471-1.041.796-1.483 1.206-1.969 1.647Z"
          transform="translate(154.823 384.957)"
          fill="url(#kuaa-home_svg__h)"
        />
      </svg>
      <div className="splash-content">
        {props.children}
        
        <ButtonSet theme={props.theme}>
          <Button
            href="https://shop.kuaafm.org/collections/all"
            target="_blank"
            size={1}
            shape={4}
            color="tertiary"
          >
            Shop
          </Button>
          
          <ButtonOutline
            onClick={() => {
              navigate("donate");
            }}
            size={1}
            shape={4}
            color="tertiary"
          >
            Donate
          </ButtonOutline>
        </ButtonSet>
      </div>
    </SplashStyles>
  );
};

Splash.propTypes = {
  theme: PropTypes.object,
};

export default Splash;
